import Autoplay from 'embla-carousel-autoplay'
import * as React from 'react'

import { CardContent } from '@/components/ui/atoms/card'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from '@/components/ui/atoms/carousel'
import type { IBanner } from '@/types/banners'

export default function BannersHome({ banners }: { banners: Array<IBanner> }) {
  const plugin = React.useRef(Autoplay({ stopOnMouseEnter: true }))

  return (
    <>
      <Carousel
        plugins={[plugin.current]}
        className="w-full"
        onMouseEnter={plugin.current.stop}
        onMouseLeave={plugin.current.reset}
        opts={{
          loop: true,
        }}
      >
        <CarouselContent>
          {banners.map((banner, index) => (
            <CarouselItem className="pl-0" key={banner._id}>
              <CardContent className="flex items-center justify-center p-0">
                <div className="w-full">
                  <a
                    href={banner.link}
                    aria-label={`Banner página inicial - ${index}`}
                  >
                    <picture>
                      <source
                        srcSet={banner.small_image}
                        media="(max-width: 430px)"
                      />
                      <source
                        srcSet={banner.medium_image}
                        media="(max-width: 768px)"
                      />
                      <img
                        src={banner.large_image}
                        alt={`Banner página inicial - ${index}`}
                        width="1024"
                        height="600"
                        className="object-cover w-full"
                        loading="eager"
                        decoding="async"
                      />
                    </picture>
                  </a>
                </div>
              </CardContent>
            </CarouselItem>
          ))}
        </CarouselContent>
      </Carousel>
    </>
  )
}
